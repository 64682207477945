// Containers
export const boxStyle = {
    textAlign: "center",
    paddingTop: "50%"
}

export const formStyle = {
    margin: "1rem",
    width: "19rem"
}

export const stackStyle = {
    alignItems: "center",
    clear: "both"
}

// Buttons
export const backButtonStyle = {
    width: "12.5rem",
    float: "left",
    margin: "0rem 0rem 1rem 3.75rem"
}

export const updateButtonStyle = {
    marginTop: "2.5rem",
    width: "10rem"
}

// Alerts
export const alertStyle = {
    width: "100%"
}

import { Box, Button, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material"
import { Component } from "react"
import { container } from "../DIContainer"
import { Score } from "../models/Score"
import { IScoreService } from "../services/ScoreService"
import { TYPES } from "../Types"
import DataTable from "react-data-table-component"
import { Dictionary } from "../TypeAliases"
import { ICSVExporter } from "../utils/CSVExporter"
import { IDataTableFactory, TableData } from "../factories/DataTableFactory"
import { IRouter } from "../Router"
import { AlertBar } from "../ui-kit/AlertBar"
import { AlertBarType } from "../models/AlertBarType"

interface AdminDashboardProps {}

export interface AdminDashboardState {
    isLoading: boolean
    scores: Score[]
    tableData: TableData | null
    filteredTableData: Dictionary[] | null
    goToImportCandidates: boolean
    goToReassignCandidates: boolean
    autoDismiss: boolean
    messageType: AlertBarType | undefined
    message: string | null
}

export class AdminDashboard extends Component<AdminDashboardProps, AdminDashboardState> {
    private scoreService: IScoreService = container.get<IScoreService>(TYPES.IScoreService)
    private csvExporter: ICSVExporter = container.get<ICSVExporter>(TYPES.ICSVExporter)
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)

    state: AdminDashboardState = {
        isLoading: true,
        scores: [],
        tableData: null,
        filteredTableData: null,
        goToImportCandidates: false,
        goToReassignCandidates: false,
        autoDismiss: true,
        messageType: undefined,
        message: null
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Box sx={{ textAlign: "center", paddingTop: "50%" }}>
                    <CircularProgress />
                </Box>
            )
        } else if (this.state.goToImportCandidates) {
            this.router.goToImportCandidates()
        } else if (this.state.goToReassignCandidates) {
            this.router.goToReassignCandidates()
        }

        return (
            <Container maxWidth="xl">
                <Stack spacing={5}>
                    <Typography id="hero-label" variant="h4">
                        Admin Portal
                    </Typography>

                    {this.state.tableData && this.state.tableData.rows.length > 0 && (
                        <Stack spacing={5}>
                            <Stack spacing={5} direction="row">
                                <TextField
                                    id="outlined-basic"
                                    label="Search"
                                    variant="outlined"
                                    onChange={this.onFilterTextFieldChange}
                                    sx={{ width: 300 }}
                                    size="small"
                                />
                                <Button id="export-button" variant="outlined" onClick={this.exportClicked}>
                                    Export CSV
                                </Button>
                                <Button id="import-button" variant="outlined" onClick={this.importClicked}>
                                    Import Candidates
                                </Button>
                                <Button id="reassign-button" variant="outlined" onClick={this.reassignClicked}>
                                    Reassign Candidates
                                </Button>
                            </Stack>
                            <Box
                                sx={{
                                    padding: 5,
                                    border: "1px solid #f5f5f5",
                                    borderRadius: 2,
                                    boxShadow: "0px 0px 2px #f5f5f5"
                                }}
                            >
                                <DataTable
                                    className="data-table"
                                    columns={this.state.tableData.columns}
                                    data={this.state.filteredTableData ? this.state.filteredTableData : this.state.tableData.rows}
                                    pagination
                                />
                            </Box>
                        </Stack>
                    )}

                    {this.state.message && (
                        <AlertBar
                            autoDismiss={this.state.autoDismiss}
                            type={this.state.messageType}
                            message={this.state.message}
                            onClose={this.onPopupClose}
                        />
                    )}
                </Stack>
            </Container>
        )
    }

    onPopupClose = () => {
        this.setState({
            autoDismiss: true,
            message: null,
            messageType: undefined
        })
    }

    /**
     * Private
     */

    private loadData() {
        this.scoreService
            .getAllScores()
            .then((scores) => {
                this.setState({
                    isLoading: false,
                    scores: scores,
                    tableData: this.dataTableFactory.tableDataFrom(scores)
                })
            })
            .catch((_) => {
                this.setState({
                    isLoading: false,
                    autoDismiss: false,
                    messageType: AlertBarType.error,
                    message: "Failed to load the page. Please refresh and try again."
                })
            })
    }

    private onFilterTextFieldChange = (event: any) => {
        if (!this.state.tableData) {
            return
        }

        const value = event.target.value.toLowerCase()

        if (value === "") {
            this.setState({ filteredTableData: null })
            return
        }

        const filteredTableData = this.state.tableData?.rows.filter((rowData) => {
            if (rowData.reviewer && rowData.reviewer.toLowerCase().includes(value)) {
                return true
            }

            if (rowData.candidateId && rowData.candidateId.toLowerCase().includes(value)) {
                return true
            }

            if (rowData.technicalSkills && rowData.technicalSkills.toString().includes(value)) {
                return true
            }

            if (rowData.adaptability && rowData.adaptability.toString().includes(value)) {
                return true
            }

            if (rowData.creativeThinking && rowData.creativeThinking.toString().includes(value)) {
                return true
            }

            if (rowData.grit && rowData.grit.toString().includes(value)) {
                return true
            }

            if (rowData.recommendation && rowData.recommendation.toLowerCase().includes(value)) {
                return true
            }

            if (rowData.notes && rowData.notes.toLowerCase().includes(value)) {
                return true
            }

            if (rowData.totalScore && rowData.totalScore.toString().includes(value)) {
                return true
            }

            return false
        })

        this.setState({ filteredTableData: filteredTableData })
    }

    private exportClicked = () => {
        this.csvExporter.generateCSV(this.state.tableData?.rows)
    }

    private importClicked = () => {
        this.setState({ goToImportCandidates: true })
    }

    private reassignClicked = () => {
        this.setState({ goToReassignCandidates: true })
    }
}

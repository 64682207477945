import { injectable } from "inversify"
import { Score } from "../models/Score"
import { Firebase, FirebaseCollection } from "./Firebase"
import { TYPES } from "../Types"
import { container } from "../DIContainer"
import { where } from "firebase/firestore"

export interface IScoreService {
    /**
     * Given a reviewer's ID, retrieves all scores for the given reviewer.
     *
     * @param {string} tokenID the ID for a particular reviewer
     * @returns {Promise<Score[]>} the promise containing all score objects retrieved
     */
    getScoresForReviewer(tokenID: string): Promise<Score[]>

    /**
     * Updates existing scores already in storage. Should not be used for new imports.
     *
     * @param {Score[]} scores collection of scores to update
     * @returns {Promise<void>} the promise with a successful update or not
     */
    saveScores(scores: Score[]): Promise<void>

    /**
     * Saves a new collection of scores to a particular collection. The document
     * IDs for each score should be empty as this will be updated when adding
     * to storage.
     *
     * @param {Score[]} scores collection of scores to import
     * @returns {Promise<void>} the promise with a successful import or not
     */
    saveNewScores(scores: Score[]): Promise<void>

    /**
     * Retrieves all scores for a particular collection.
     *
     * @returns {Promise<Score[]>} the promise containing all score objects retrieved
     */
    getAllScores(): Promise<Score[]>
}

@injectable()
export class ScoreService implements IScoreService {
    private firebase: Firebase = container.get<Firebase>(TYPES.IFirebase)

    getScoresForReviewer(tokenID: string): Promise<Score[]> {
        return new Promise((resolve, reject) => {
            this.firebase
                .getFrom(FirebaseCollection.scores2024, where("rPid", "==", tokenID), Score.Converter)
                .then((querySnapshot) => {
                    const scores: Score[] = querySnapshot.docs.map((doc) => {
                        const score = doc.data()
                        score.documentId = doc.id
                        return score
                    })

                    resolve(scores)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    saveScores(scores: Score[]): Promise<void> {
        return new Promise((resolve, reject) => {
            this.firebase
                .batchUpdate(FirebaseCollection.scores2024, scores)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    saveNewScores(scores: Score[]): Promise<void> {
        scores.forEach((score: Score) => {
            if (score.documentId) {
                return Promise.reject("Scores should not contain a Document ID.")
            }
        })

        return new Promise((resolve, reject) => {
            this.firebase
                .batchAdd(FirebaseCollection.scores2024, scores)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    getAllScores(): Promise<Score[]> {
        return new Promise((resolve, reject) => {
            this.firebase
                .getAllFrom(FirebaseCollection.scores2024, Score.Converter)
                .then((querySnapshot) => {
                    const scores: Score[] = querySnapshot.docs.map((doc) => {
                        const score = doc.data()
                        score.documentId = doc.id
                        return score
                    })

                    resolve(scores)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

const TYPES = {
    /**
     * Singletons
     */
    IFirebase: Symbol.for("IFirebase"),

    /**
     * Services
     */
    IScoreService: Symbol.for("IScoreService"),
    IAuthService: Symbol.for("IAuthService"),

    /**
     * Classes
     */
    IURLUtils: Symbol.for("IURLUtils"),
    IURLProvider: Symbol.for("IURLProvider"),
    IRouter: Symbol.for("IRouter"),
    IValidator: Symbol.for("IValidator"),
    ICSVExporter: Symbol.for("ICSVExporter"),
    ICandidateUploadValidator: Symbol.for("ICandidateUploadValidator"),

    /**
     * Factories
     */
    IDataTableFactory: Symbol.for("IDataTableFactory")
}

export { TYPES }

/* istanbul ignore file */
import { injectable } from "inversify"
import { ExportToCsv } from "export-to-csv"

export interface ICSVExporter {
    generateCSV(data: any): void
}

@injectable()
export class CSVExporter implements ICSVExporter {
    generateCSV(data: any): void {
        const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)

        csvExporter.generateCsv(data)
    }
}

import { injectable } from "inversify"

/* istanbul ignore file */
export interface IURLProvider {
    url(): string
}

@injectable()
export class URLProvider implements IURLProvider {
    url(): string {
        return document.URL
    }
}

import { Component } from "react"
import { Dictionary } from "../TypeAliases"
import { CandidateView } from "./CandidateView"
import { Stack } from "@mui/material"
import { Drawer, IconButton, List, ListItem, Typography } from "@mui/material"
import { ReactComponent as ChevronLeftIcon } from "../images/noun-chevron-left-1226889.svg"
import { ReactComponent as ChevronRightIcon } from "../images/noun-chevron-right-1226888.svg"
import { drawerStyle, headerStackStyle, iconStyle } from "./styles/CandidatesCollection.style"

interface CandidatesCollectionProps {
    selectedCandidate: string
    candidateProgress: Dictionary // e.g. { "candidateId": 0.33, ... }
    onClick: (candidateId: string) => void
}

interface CandidatesCollectionState {
    isOpen: boolean
}

export class CandidatesCollection extends Component<CandidatesCollectionProps, CandidatesCollectionState> {
    state: CandidatesCollectionState = {
        isOpen: true
    }

    private checkIfSelected(candidateId: string): boolean {
        return candidateId === this.props.selectedCandidate
    }

    render() {
        return (
            <Stack id="candidates-container" spacing={2}>
                {(!this.props.candidateProgress || !Object.keys(this.props.candidateProgress).length) && (
                    <Typography id="empty-container">You have not been assigned any candidates.</Typography>
                )}

                {this.props.candidateProgress && Object.keys(this.props.candidateProgress).length && (
                    <Drawer variant="permanent" open={this.state.isOpen} sx={drawerStyle}>
                        <Stack direction="row" spacing={2} sx={headerStackStyle}>
                            {this.determineDrawerHeader()}
                        </Stack>
                        <List>
                            {Object.entries(this.props.candidateProgress).map(([candidateId, progress]) => {
                                return (
                                    <ListItem key={candidateId} id={candidateId} onClick={() => this.handleOnClick(candidateId)}>
                                        <CandidateView
                                            candidateId={candidateId}
                                            progress={progress}
                                            isOpen={this.state.isOpen}
                                            isSelected={this.checkIfSelected(candidateId)}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Drawer>
                )}
            </Stack>
        )
    }

    handleOnClick = (candidateId: string) => {
        this.props.onClick(candidateId)
    }

    handleDrawerOpen = () => {
        this.setState({ isOpen: true })
    }

    handleDrawerClose = () => {
        this.setState({ isOpen: false })
    }

    private determineDrawerHeader = () => {
        if (this.state.isOpen) {
            return (
                <>
                    <Typography variant={"h5"}>Candidates</Typography>
                    <IconButton aria-label="close drawer" onClick={() => this.handleDrawerClose()}>
                        <ChevronLeftIcon style={iconStyle} />
                    </IconButton>
                </>
            )
        }

        return (
            <IconButton aria-label="open drawer" onClick={() => this.handleDrawerOpen()}>
                <ChevronRightIcon style={iconStyle} />
            </IconButton>
        )
    }
}

import { injectable } from "inversify"
import { Score } from "../models/Score"
import { Dictionary } from "../TypeAliases"

export interface IDataTableFactory {
    tableDataFrom(scores: Score[]): TableData
    createCandidateUploadPreviewTableData(data: Dictionary[]): TableData
}

export interface TableData {
    rows: Dictionary[]
    columns: Dictionary[]
}

@injectable()
export class DataTableFactory implements IDataTableFactory {
    tableDataFrom(scores: Score[]): TableData {
        const columns: Dictionary[] = [
            {
                name: "Reviewer",
                selector: (row: any) => row.reviewer,
                sortable: true,
                minWidth: "1em"
            },
            {
                name: "Candidate Id",
                selector: (row: any) => row.candidateId,
                sortable: true,
                minWidth: "1em"
            },
            {
                name: "Creative Thinking",
                selector: (row: any) => row.creativeThinking,
                sortable: true,
                maxWidth: "1em",
                center: true
            },
            {
                name: "Adaptability",
                selector: (row: any) => row.adaptability,
                sortable: true,
                maxWidth: "1em",
                center: true
            },
            {
                name: "Technical Skills",
                selector: (row: any) => row.technicalSkills,
                sortable: true,
                maxWidth: "1em",
                center: true
            },
            {
                name: "Grit",
                selector: (row: any) => row.grit,
                sortable: true,
                maxWidth: "1em",
                center: true
            },
            {
                name: "Total Score",
                selector: (row: any) => row.totalScore,
                sortable: true,
                maxWidth: "1em",
                center: true
            },
            {
                name: "Recommendation",
                selector: (row: any) => row.recommendation,
                sortable: true,
                maxWidth: "1em",
                center: true
            },
            {
                name: "Notes",
                selector: (row: any) => row.notes,
                wrap: true
            }
        ]

        const rows = scores.map((score) => {
            return {
                id: score.documentId,
                reviewer: `${score.rFirstName} ${score.rLastName}`,
                candidateId: score.cPid,
                creativeThinking: score.creativeThinking.toString(),
                adaptability: score.adaptability.toString(),
                technicalSkills: score.technicalSkills.toString(),
                grit: score.grit.toString(),
                totalScore: score.totalScore().toString(),
                recommendation: score.recommendation,
                notes: score.notes
            }
        })

        return {
            rows: rows,
            columns: columns
        }
    }

    createCandidateUploadPreviewTableData(data: Dictionary[]): TableData {
        const columns: Dictionary[] = [
            {
                name: "Candidate Id",
                selector: (row: any) => row.candidateId,
                sortable: true,
                minWidth: "1em"
            },
            {
                name: "Reviewer",
                selector: (row: any) => row.reviewer,
                sortable: true,
                minWidth: "1em"
            },
            {
                name: "Reviewer Id",
                selector: (row: any) => row.reviewerId,
                sortable: true,
                minWidth: "1em"
            }
        ]

        let rows = data.map((entry) => {
            return {
                candidateId: entry.cPid.toString(),
                reviewer: `${entry.rFirstName} ${entry.rLastName}`,
                reviewerId: entry.rPid.toString()
            }
        })

        return {
            rows: rows,
            columns: columns
        }
    }
}

import { Component } from "react"
import { Box, CircularProgress, Typography } from "@mui/material"
import { Paper, ListItemButton, ListItemText, ListItemIcon } from "@mui/material"
import { childBoxStyle, parentBoxStyle } from "./styles/CandidateView.style"

interface CandidateViewProps {
    candidateId: string
    progress: number
    isOpen: boolean
    isSelected: boolean
}

export class CandidateView extends Component<CandidateViewProps> {
    render() {
        return (
            <Paper elevation={2}>
                <ListItemButton
                    id="candidate-view-button"
                    selected={this.props.isSelected}
                    style={{
                        backgroundColor: this.props.isSelected ? "#d9d9d9" : "#ffffff"
                    }}
                >
                    <ListItemIcon>
                        <Box sx={parentBoxStyle}>
                            <CircularProgress id="candidate-view-progress" variant="determinate" value={this.getProgress()} />
                            <Box sx={childBoxStyle}>
                                <Typography variant="caption" component="div">{`${this.getProgress()}%`}</Typography>
                            </Box>
                        </Box>
                    </ListItemIcon>
                    {this.props.isOpen && <ListItemText id={"candidate-view-label"} primary={this.props.candidateId} />}
                </ListItemButton>
            </Paper>
        )
    }

    private getProgress = () => {
        return this.props.progress * 100
    }
}

import { injectable } from "inversify"
import { PathNames } from "./PathNames"
import history from "./History"

export interface IRouter {
    location(): string
    goBack(): void
    refreshPage(): void
    goToAdmin(): void
    goToAdminDashboard(): void
    goToImportCandidates(): void
    goToReassignCandidates(): void
}

@injectable()
export class Router implements IRouter {
    location(): string {
        return window.location.href
    }

    goBack(): void {
        history.goBack()
    }

    refreshPage(): void {
        window.location.reload()
    }

    goToAdmin(): void {
        history.push(PathNames.Admin)
    }

    goToAdminDashboard(): void {
        history.push(PathNames.AdminDashboard)
    }

    goToImportCandidates(): void {
        history.push(PathNames.ImportCandidates)
    }

    goToReassignCandidates(): void {
        history.push(PathNames.ReassignCandidates)
    }
}

import { Component } from "react"
import { Box, Button, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material"
import { buttonStyle, boxStyle, loadingProgressStyle, searchFieldStyle, stackStyle } from "./styles/ImportCandidates.style"
import "./styles/ImportCandidates.css"
import { TYPES } from "../Types"
import { Dictionary } from "../TypeAliases"
import { container } from "../DIContainer"
import { IRouter } from "../Router"
import { ICandidateUploadValidator, CandidateUploadError } from "../services/CandidateUploadValidator"
import { IDataTableFactory, TableData } from "../factories/DataTableFactory"
import CSVReader, { IFileInfo } from "react-csv-reader"
import DataTable from "react-data-table-component"
import { AlertBarType } from "../models/AlertBarType"
import { AlertBar } from "../ui-kit/AlertBar"
import { Score } from "../models/Score"
import { ScoreMock } from "../mocks/Score.mock"
import { IScoreService } from "../services/ScoreService"

interface ImportCandidatesProps {}

export interface ImportCandidatesState {
    goToAdminDashboard: boolean
    isLoading: boolean
    csvErrors: CandidateUploadError[] | null
    tableData: TableData | null
    filteredTableData: Dictionary[] | null
    candidatesToUpload: Score[]
    newCandidatesCount: number
    selectedFileName: string
    autoDismiss: boolean
    messageType: AlertBarType | undefined
    message: string | null
}

export class ImportCandidates extends Component<ImportCandidatesProps, ImportCandidatesState> {
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)
    private dataValidator: ICandidateUploadValidator = container.get<ICandidateUploadValidator>(TYPES.ICandidateUploadValidator)
    private dataTableFactory: IDataTableFactory = container.get<IDataTableFactory>(TYPES.IDataTableFactory)
    private scoreService: IScoreService = container.get<IScoreService>(TYPES.IScoreService)

    state: ImportCandidatesState = {
        goToAdminDashboard: false,
        isLoading: false,
        csvErrors: null,
        tableData: null,
        filteredTableData: null,
        candidatesToUpload: [],
        newCandidatesCount: 0,
        selectedFileName: "no file selected",
        autoDismiss: true,
        messageType: undefined,
        message: null
    }

    // For the react CSV reader
    private parserOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Box sx={loadingProgressStyle}>
                    <CircularProgress />
                </Box>
            )
        } else if (this.state.goToAdminDashboard) {
            this.router.goToAdminDashboard()
        }

        return (
            <Container maxWidth="xl">
                <Button id="back-button" sx={buttonStyle} variant="outlined" onClick={this.backToAdminHomeClicked}>
                    Back to Admin Home
                </Button>
                <Stack spacing={5} sx={stackStyle}>
                    <Typography variant="h4" id="import-title">
                        Import Candidates
                    </Typography>
                    <Stack spacing={5} direction="row">
                        <Button id="download-template-button" variant="outlined" onClick={this.downloadTemplateClicked}>
                            Download CSV Template
                        </Button>
                        <Button id="select-csv-button" variant="outlined" component="label">
                            <CSVReader
                                inputId="csv-reader"
                                label="Select CSV File"
                                cssLabelClass="csv-label"
                                cssInputClass="csv-input"
                                onFileLoaded={this.fileLoaded}
                                parserOptions={this.parserOptions}
                            />
                        </Button>
                    </Stack>
                    <Typography id="selected-file-title" variant="h6">
                        Selected File: {this.state.selectedFileName}
                    </Typography>
                </Stack>

                {this.state.tableData && this.state.tableData.rows && (
                    <Stack spacing={5}>
                        <TextField
                            id="search-text-field"
                            label="Search"
                            variant="outlined"
                            onChange={this.onFilterTextFieldChange}
                            sx={searchFieldStyle}
                            size="small"
                        />
                        <Typography variant="body1">All candidates will be imported with empty score values.</Typography>
                        <Box sx={boxStyle}>
                            <DataTable
                                className="data-table"
                                columns={this.state.tableData.columns}
                                data={this.state.filteredTableData ? this.state.filteredTableData : this.state.tableData.rows}
                                pagination
                            />
                        </Box>
                        <Typography id="number-candidates-count" variant="body1">
                            Number of candidates to import: {this.state.newCandidatesCount}
                        </Typography>
                        <Button id="import-button" sx={buttonStyle} variant="contained" onClick={this.importCandidatesClicked}>
                            Import Candidates
                        </Button>
                    </Stack>
                )}

                {this.state.csvErrors && (
                    <Stack spacing={2} id="csv-error-stack">
                        <Typography variant="h6">
                            <strong>Errors found in the CSV File:</strong>
                        </Typography>
                        {this.state.csvErrors.map((value, index) => {
                            return (
                                <Typography id={`error${index}`} key={index} variant="body1">
                                    {++index}. {value.message}
                                </Typography>
                            )
                        })}
                        <Typography variant="h6">
                            <strong>Please fix the issue(s) and try to upload again.</strong>
                        </Typography>
                    </Stack>
                )}

                {this.state.message && (
                    <AlertBar
                        autoDismiss={this.state.autoDismiss}
                        type={this.state.messageType}
                        message={this.state.message}
                        onClose={this.onPopupClose}
                    />
                )}
            </Container>
        )
    }

    clearCsvErrors() {
        this.setState({ csvErrors: null })
        const errorStack = document.getElementById("#csv-error-stack")
        if (errorStack) {
            errorStack.remove()
        }
    }

    clearTableData() {
        this.setState({
            tableData: null,
            filteredTableData: null,
            newCandidatesCount: 0,
            candidatesToUpload: []
        })
    }

    onPopupClose = () => {
        this.setState({
            autoDismiss: true,
            message: null,
            messageType: undefined
        })
    }

    resetPageState = () => {
        this.clearTableData()
        this.setState({
            selectedFileName: "no file selected"
        })
    }

    toggleLoadingState = () => {
        let isLoading: boolean
        this.state.isLoading ? (isLoading = false) : (isLoading = true)

        this.setState({
            isLoading: isLoading
        })
    }

    /**
     * Private
     */
    private backToAdminHomeClicked = () => {
        this.setState({ goToAdminDashboard: true })
    }

    private downloadTemplateClicked = () => {
        if (process.env.REACT_APP_ENV_CSV_TEMPLATE_DOWNLOAD_URL) {
            window.location.assign(process.env.REACT_APP_ENV_CSV_TEMPLATE_DOWNLOAD_URL)
            this.setState({
                messageType: AlertBarType.success,
                message: "The template has downloaded."
            })
        } else {
            this.setState({
                messageType: AlertBarType.error,
                message: "The template failed to download, please try again later."
            })
        }
    }

    private importCandidatesClicked = () => {
        if (!this.state.candidatesToUpload) {
            this.setState({
                messageType: AlertBarType.error,
                message: "No candidates to import."
            })
            return
        }

        this.toggleLoadingState()

        this.scoreService
            .saveNewScores(this.state.candidatesToUpload)
            .then(() => {
                this.resetPageState()
                this.setState({
                    messageType: AlertBarType.success,
                    message: "The new candidates have been successfully imported."
                })
            })
            .catch((_) => {
                this.setState({
                    messageType: AlertBarType.error,
                    message: "Failed to import new candidates. Please refresh and try again."
                })
            })
            .finally(() => {
                this.toggleLoadingState()
            })
    }

    // Public until able to mock csv input files - would like to make private
    // see comment line 167 of ImportCandidates.test.tsx
    fileLoaded = (data: Dictionary[], fileInfo: IFileInfo) => {
        this.setState({ selectedFileName: fileInfo.name })

        if (this.state.tableData) {
            // if previous table data were set, reset the table
            this.clearTableData()
        } else if (this.state.csvErrors) {
            // if previous errors were set, reset the errors
            this.clearCsvErrors()
        }

        const validationErrors = this.dataValidator.validate(data)
        if (validationErrors.errors) {
            this.setState({
                csvErrors: validationErrors.errors
            })
            return
        }

        const scores: Score[] = this.generateScoresDataFromCSVData(data)
        this.setState({
            tableData: this.dataTableFactory.createCandidateUploadPreviewTableData(data),
            newCandidatesCount: scores.length,
            candidatesToUpload: scores
        })

        /**
         * This is a hack to clear out the input of the csv file or else it won't take multiple uploads
         * Link to issue: https://github.com/nzambello/react-csv-reader/issues/45
         */
        const csvReader = document.getElementById("csv-reader") as HTMLInputElement
        if (csvReader !== undefined && csvReader !== null) {
            csvReader.value = ""
        }
    }

    /**
     * Generates a collection of candidates ready for uploading to storage from
     * an uploaded csv file
     * @param {Dictionary[]} csvData the candidates from an uploaded csv file
     * @returns {Score[]} the collection of candidates ready for uploading
     */
    private generateScoresDataFromCSVData(csvData: Dictionary[]): Score[] {
        this.toggleLoadingState()

        let scores: Score[] = []

        csvData.forEach((newCandidate) => {
            const newScore: Score = ScoreMock({
                cPid: newCandidate.cPid,
                rPid: newCandidate.rPid,
                rFirstName: newCandidate.rFirstName,
                rLastName: newCandidate.rLastName
            })
            scores.push(newScore)
        })

        this.toggleLoadingState()
        return scores
    }

    private onFilterTextFieldChange = (event: any) => {
        if (!this.state.tableData) {
            return
        }

        const value = event.target.value.toLowerCase()

        if (value === "") {
            this.setState({ filteredTableData: null })
            return
        }

        const filteredTableData = this.state.tableData.rows.filter((rowData) => {
            if (rowData.candidateId && rowData.candidateId.toLowerCase().includes(value)) {
                return true
            }

            if (rowData.reviewer && rowData.reviewer.toLowerCase().includes(value)) {
                return true
            }

            if (rowData.reviewerId && rowData.reviewerId.toLowerCase().includes(value)) {
                return true
            }

            return false
        })

        this.setState({ filteredTableData: filteredTableData })
    }
}

import { Dictionary, DocumentId } from "../TypeAliases"

export interface Convertible {
    toJson(): Dictionary
    documentId: DocumentId
}

export class Score implements Convertible {
    documentId: DocumentId
    rFirstName: string
    rLastName: string
    rPid: string
    cPid: string
    adaptability: number
    creativeThinking: number
    grit: number
    technicalSkills: number
    notes: string
    recommendation: string

    constructor(
        documentId: DocumentId,
        rFirstName: string,
        rLastName: string,
        rPid: string,
        cPid: string,
        adaptability: number,
        creativeThinking: number,
        grit: number,
        technicalSkills: number,
        notes: string,
        recommendation: string
    ) {
        this.documentId = documentId
        this.rFirstName = rFirstName
        this.rLastName = rLastName
        this.rPid = rPid
        this.cPid = cPid
        this.adaptability = adaptability
        this.creativeThinking = creativeThinking
        this.grit = grit
        this.technicalSkills = technicalSkills
        this.notes = notes
        this.recommendation = recommendation
    }

    toJson(): Dictionary {
        return {
            documentId: this.documentId,
            rFirstName: this.rFirstName,
            rLastName: this.rLastName,
            rPid: this.rPid,
            cPid: this.cPid,
            adaptability: this.adaptability,
            creativeThinking: this.creativeThinking,
            grit: this.grit,
            technicalSkills: this.technicalSkills,
            notes: this.notes,
            recommendation: this.recommendation
        }
    }

    totalScore(): number {
        return this.adaptability + this.creativeThinking + this.grit + this.technicalSkills
    }

    /**
     * Returns a perctange value representing a reviewer's completion progress
     * for a candidate on scale of [0,1] inclusively.
     *
     * @returns {number} - the completion progress [0,1] inclusively
     */
    getScoreProgress(): number {
        let completedItems: number = 0
        const fieldsForProgress: any[] = [this.creativeThinking, this.adaptability, this.technicalSkills, this.grit, this.recommendation, this.notes]

        const totalNumValues: number = fieldsForProgress.length
        for (let i: number = 0; i < totalNumValues; i++) {
            if (fieldsForProgress[i]) {
                completedItems += 1
            }
        }

        return this.normalize(completedItems, totalNumValues)
    }

    /**
     * Helper function to calculate the percentage of completed reviewer tasks
     * for a candidate.
     *
     * @param {number} completedItems - the number of form items a reviewer has completed
     * @param {number} max - the total number of form items that should be assesed for progress
     * @returns {number} - number [0,1] inclusive represent the percentage of completion rounded to two significant digits
     */
    private normalize(completedItems: number, max: number): number {
        const evaluation: number = completedItems / max
        return Number(evaluation.toPrecision(2))
    }

    static Converter = {
        toFirestore: function (score: Score) {
            return score.toJson()
        },
        fromFirestore: function (snapshot: any, options: any) {
            const data = snapshot.data(options)

            return new Score(
                data.documentId,
                data.rFirstName,
                data.rLastName,
                data.rPid,
                data.cPid,
                data.adaptability,
                data.creativeThinking,
                data.grit,
                data.technicalSkills,
                data.notes,
                data.recommendation
            )
        }
    }
}

import "./App.css"
import { Component } from "react"
import { Route, Switch } from "react-router-dom"
import { EvaluationForm } from "./evaluation-form/EvaluationForm"
import { AdminLogin } from "./admin/AdminLogin"
import { AdminDashboard } from "./admin/AdminDashboard"
import { ReassignCandidates } from "./admin/ReassignCandidates"
import { PathNames } from "./PathNames"
import { ImportCandidates } from "./admin/ImportCandidates"

interface AppProps {}
interface AppState {}

export class App extends Component<AppProps, AppState> {
    render() {
        return (
            <div className="App">
                <Switch>
                    <Route exact path={PathNames.Home} component={EvaluationForm} />
                    <Route path={PathNames.Admin} component={AdminLogin} />
                    <Route path={PathNames.AdminDashboard} component={AdminDashboard} />
                    <Route path={PathNames.ReassignCandidates} component={ReassignCandidates} />
                    <Route path={PathNames.ImportCandidates} component={ImportCandidates} />
                </Switch>
            </div>
        )
    }
}

import { Component } from "react"
import { Dictionary } from "./TypeAliases"
import { Stack } from "@mui/material"
import { CandidatesCollection } from "./admin/CandidatesCollection"

interface CandidateSelectorProps {
    selectedCandidate: string
    candidateProgress: Dictionary // e.g. { "candidateId": 0.33, ... }
    onCandidateChange: (index: number) => void
}

export class CandidateSelector extends Component<CandidateSelectorProps> {
    render() {
        return (
            <Stack maxWidth="lg" alignItems={"center"} spacing={4}>
                <CandidatesCollection
                    selectedCandidate={this.props.selectedCandidate}
                    candidateProgress={this.props.candidateProgress}
                    onClick={this.onCandidateChange}
                />
            </Stack>
        )
    }

    onCandidateChange = (candidateId: string) => {
        const index = Object.keys(this.props.candidateProgress).indexOf(candidateId)
        this.props.onCandidateChange(index)
    }
}

import { Component } from "react"
import { Alert, Snackbar } from "@mui/material"
import { alertStyle } from "./styles/AlertBar.style"
import { AlertBarType } from "../models/AlertBarType"

interface AlertBarProps {
    autoDismiss: boolean
    type: AlertBarType | undefined
    message: string
    onClose: () => void
}

interface SnackBarState {}

export class AlertBar extends Component<AlertBarProps, SnackBarState> {
    render() {
        return (
            <Snackbar
                id={`${this.props.type}-alert`}
                open={true}
                autoHideDuration={this.props.autoDismiss ? 6000 : null}
                onClose={this.props.autoDismiss ? this.props.onClose : undefined}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert
                    id="alertbar-alert"
                    onClose={this.props.autoDismiss ? this.props.onClose : undefined}
                    severity={this.props.type}
                    style={alertStyle}
                >
                    {this.props.message}
                </Alert>
            </Snackbar>
        )
    }
}

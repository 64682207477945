// Containers
export const stackStyle = {
    alignItems: "center",
    clear: "both",
    marginBottom: "2rem"
}

export const boxStyle = {
    padding: "0.3125rem",
    border: "0.0625rem solid #f5f5f5",
    borderRadius: "0.125rem",
    boxShadow: "0rem 0rem 0.125rem #f5f5f5"
}

// Search TextField
export const searchFieldStyle = {
    width: "18.75rem"
}

// Buttons
export const buttonStyle = {
    width: "12.5rem",
    float: "left",
    margin: "0rem 0rem 1rem 3.75rem"
}

// Circular Progress
export const loadingProgressStyle = {
    textAlign: "center",
    paddingTop: "50%"
}

import { Score } from "../models/Score"

const defaultScore = (): Score => {
    return new Score(
        "", // documentId
        "", // Reviewer's First Name
        "", // Reviewer's Last Name
        "", // Reviewer's ID
        "", // Candidate's ID
        0, // Adaptability Score
        0, // Creative Thinking Score
        0, // Grit Score
        0, // Technical Skills Score
        "", // Notes
        "" // Recommendation
    )
}

export const ScoreMock = (p: Partial<Score> = {}): Score => {
    return Object.assign(defaultScore(), p)
}

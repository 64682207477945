import { injectable } from "inversify"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { IFirebase } from "./Firebase"

export interface IAuthService {
    /**
     * Login a user
     * @param username The username
     * @param password The password
     */
    login(username: string, password: string): Promise<void>
}

@injectable()
export class AuthService implements IAuthService {
    private firebase: IFirebase = container.get<IFirebase>(TYPES.IFirebase)

    login(username: string, password: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.firebase
                .login(username, password)
                .then((_) => {
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

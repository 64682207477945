import { Stack, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material"
import { Component } from "react"
import { Score } from "../models/Score"

interface OverallRecommendationProps {
    score: Score
    onChange: (recommendation: OverallRecommendationOptions) => void
}

interface OverallRecommendationState {}

export enum OverallRecommendationOptions {
    yes = "yes",
    waitlist = "waitlist",
    no = "no"
}

export class OverallRecommendation extends Component<OverallRecommendationProps, OverallRecommendationState> {
    render() {
        return (
            <Stack spacing={2}>
                <Typography align="left" variant="h6">
                    Overall Recommendation
                </Typography>
                <Stack direction="row" spacing={5} alignItems="center">
                    <Typography align="left">Is the candidate prepared/qualified to join the Summer Academy?</Typography>
                    <RadioGroup
                        row
                        id="recommendation-radio-group"
                        aria-labelledby="radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={this._onChange}
                        value={this.props.score.recommendation}
                        sx={{ marginRight: 0 }}
                    >
                        <FormControlLabel value={OverallRecommendationOptions.yes} control={<Radio />} label="Yes" />
                        <FormControlLabel value={OverallRecommendationOptions.waitlist} control={<Radio />} label="Waitlist" />
                        <FormControlLabel value={OverallRecommendationOptions.no} control={<Radio />} label="No" />
                    </RadioGroup>
                </Stack>
            </Stack>
        )
    }

    _onChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        this.props.onChange(value as OverallRecommendationOptions)
    }
}

import { Stack, Button, Container, Typography, Box, CircularProgress } from "@mui/material"
import { Component } from "react"
import { CandidateSelector } from "../CandidateSelector"
import { container } from "../DIContainer"
import { EvaluationCategory, EvaluationCategoryData, EvalCategory } from "../evaluation-category/EvaluationCategory"
import { EvaluationNotes } from "../evaluation-notes/EvaluationNotes"
import { Score } from "../models/Score"
import { OverallRecommendation, OverallRecommendationOptions } from "../overall-recommendation/OverallRecommendation"
import { IScoreService } from "../services/ScoreService"
import { TYPES } from "../Types"
import { Dictionary } from "../TypeAliases"
import { IURLUtils } from "../utils/URLUtils"
import { IValidator } from "./Validator"
import { AlertBar } from "../ui-kit/AlertBar"
import { AlertBarType } from "../models/AlertBarType"

interface EvaluationFormProps {}

export interface EvaluationFormState {
    isLoading: boolean
    scores: Score[]
    selectedIndex: number
    isValidTokenId: boolean
    rFirstName: string // reviewer's name
    rLastName: string // reviewer's name
    autoDismiss: boolean
    messageType: AlertBarType | undefined
    message: string | null
}

export class EvaluationForm extends Component<EvaluationFormProps, EvaluationFormState> {
    private scoreService: IScoreService = container.get<IScoreService>(TYPES.IScoreService)
    private urlUtils: IURLUtils = container.get<IURLUtils>(TYPES.IURLUtils)
    private validator: IValidator = container.get<IValidator>(TYPES.IValidator)

    state: EvaluationFormState = {
        isLoading: true,
        scores: [],
        selectedIndex: 0,
        isValidTokenId: true,
        rFirstName: "",
        rLastName: "",
        autoDismiss: true,
        messageType: undefined,
        message: null
    }

    componentDidMount() {
        let tokenId = this.urlUtils.getTokenId()
        if (!tokenId) {
            this.setState({
                messageType: AlertBarType.error,
                message: "You are not authorized to view this page.",
                isLoading: false,
                autoDismiss: false
            })
            return
        }

        this.scoreService
            .getScoresForReviewer(tokenId)
            .then((scores) => {
                if (scores.length) {
                    this.setState({
                        rFirstName: scores[0].rFirstName,
                        rLastName: scores[0].rLastName
                    })
                }
                this.setState({
                    scores: scores,
                    selectedIndex: this.state.selectedIndex
                })
            })
            .catch((_) => {
                this.setState({
                    messageType: AlertBarType.error,
                    message: "No Scores Returned"
                })
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Box sx={{ textAlign: "center", paddingTop: "50%" }}>
                    <CircularProgress />
                </Box>
            )
        }

        return (
            <div className="App">
                <>
                    <Container maxWidth="lg">
                        <Stack spacing={5}>
                            {this.state.scores.length === 0 && !this.state.message && (
                                <Typography id="empty-view">You have not been assigned any candidates.</Typography>
                            )}

                            {this.state.scores.length > 0 && (
                                <>
                                    <Typography id="hero-label" variant="h3">
                                        Summer Academy Evaluation Form (Round 2)
                                    </Typography>
                                    <Typography id="reviewer-label" variant="h4">
                                        Hi {this.state.rFirstName + " " + this.state.rLastName}!
                                    </Typography>
                                    <Stack direction="row" spacing={2}>
                                        <CandidateSelector
                                            selectedCandidate={this.state.scores[this.state.selectedIndex].cPid}
                                            candidateProgress={this.createSelectorData()}
                                            onCandidateChange={this.onCandidateChangeHandler}
                                        />
                                        <Stack spacing={5}>
                                            <EvaluationCategory
                                                score={this.state.scores[this.state.selectedIndex]}
                                                onChange={this.onCategoryChangeHandler}
                                            />
                                            <OverallRecommendation
                                                score={this.state.scores[this.state.selectedIndex]}
                                                onChange={this.onRecommendationChangeHandler}
                                            />
                                            <EvaluationNotes
                                                score={this.state.scores[this.state.selectedIndex]}
                                                onChange={this.onNoteChangeHandler}
                                            />
                                            <Stack direction="row" spacing={2}>
                                                <Button
                                                    id="save-button"
                                                    variant="outlined"
                                                    size="large"
                                                    onClick={this.saveClick}
                                                    sx={{ width: "100px" }}
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    id="submit-button"
                                                    variant="contained"
                                                    size="large"
                                                    onClick={this.submitClicked}
                                                    sx={{ width: "120px" }}
                                                >
                                                    Submit
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </>
                            )}

                            {this.state.message && (
                                <AlertBar
                                    autoDismiss={this.state.autoDismiss}
                                    type={this.state.messageType}
                                    message={this.state.message}
                                    onClose={this.onPopupClose}
                                />
                            )}
                        </Stack>
                    </Container>
                </>
            </div>
        )
    }

    onPopupClose = () => {
        this.setState({
            autoDismiss: true,
            message: null,
            messageType: undefined
        })
    }

    onCandidateChangeHandler = (index: number) => {
        this.setState({ selectedIndex: index })
    }

    onRecommendationChangeHandler = (recommendation: OverallRecommendationOptions) => {
        const score = this.state.scores[this.state.selectedIndex]
        score.recommendation = recommendation

        this.setState({ scores: this.state.scores })
    }

    onCategoryChangeHandler = (categoryData: EvaluationCategoryData) => {
        const score = this.state.scores[this.state.selectedIndex]

        switch (categoryData.category) {
            case EvalCategory.adaptability:
                score.adaptability = categoryData.value
                break

            case EvalCategory.creativeThinking:
                score.creativeThinking = categoryData.value
                break

            case EvalCategory.grit:
                score.grit = categoryData.value
                break

            case EvalCategory.technicalSkills:
                score.technicalSkills = categoryData.value
                break
        }

        this.setState({ scores: this.state.scores })
    }

    onNoteChangeHandler = (newValue: string) => {
        const score = this.state.scores[this.state.selectedIndex]
        score.notes = newValue

        this.setState({ scores: this.state.scores })
    }

    private createSelectorData(): Dictionary {
        const data: Dictionary = {}
        this.state.scores.forEach((score) => {
            data[score.cPid] = score.getScoreProgress()
        })

        return data
    }

    private saveClick = () => {
        this.scoreService
            .saveScores(this.state.scores)
            .then(() => {
                this.setState({
                    messageType: AlertBarType.success,
                    message: "All candidate scores have been saved."
                })
            })
            .catch((_) => {
                this.setState({
                    messageType: AlertBarType.error,
                    message: "Saving scores failed. Please try again."
                })
            })
    }

    private createInvalidErrorMessageWith = (candidateIds: string[]): string => {
        let candidateIdsValue = ""

        for (let i = 0; i < candidateIds.length; i++) {
            candidateIdsValue += candidateIds[i]

            if (i !== candidateIds.length - 1) {
                candidateIdsValue += ", "
            }
        }

        return "The following candidates need more work: " + candidateIdsValue
    }

    private submitClicked = () => {
        const invalidCandidateIds = this.validator.isValidScores(this.state.scores)

        if (invalidCandidateIds.length > 0) {
            this.setState({
                messageType: AlertBarType.error,
                message: this.createInvalidErrorMessageWith(invalidCandidateIds)
            })

            return
        }

        this.scoreService
            .saveScores(this.state.scores)
            .then(() => {
                this.setState({
                    messageType: AlertBarType.success,
                    message: "Thank you for your submission!"
                })
            })
            .catch((_) => {
                this.setState({
                    messageType: AlertBarType.error,
                    message: "Submitting failed. Please try again."
                })
            })
    }
}

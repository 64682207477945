import { injectable } from "inversify"
import queryString from "query-string"
import { container } from "../DIContainer"
import { TYPES } from "../Types"
import { IURLProvider } from "./URLProvider"

export interface IURLUtils {
    /**
     * Helper function to query for a specific parameter in the current URL.
     * @param name The parameter name
     * @returns The value for a query parameter in a URL
     */
    getTokenId(): string | null
}

@injectable()
export class URLUtils implements IURLUtils {
    private urlProvider: IURLProvider = container.get<IURLProvider>(TYPES.IURLProvider)

    getTokenId(): string | null {
        const components = this.urlProvider.url().split("?")

        if (components.length !== 2) {
            return null
        }

        const params = queryString.parse(components[1])
        const code = params["tokenID"]

        if (!code) {
            return null
        }

        return code as string
    }
}

import { Container } from "inversify"
import { IValidator, Validator } from "./evaluation-form/Validator"
import { DataTableFactory, IDataTableFactory } from "./factories/DataTableFactory"
import { Router, IRouter } from "./Router"
import { AuthService, IAuthService } from "./services/AuthService"
import { Firebase, IFirebase } from "./services/Firebase"
import { IScoreService, ScoreService } from "./services/ScoreService"
import { TYPES } from "./Types"
import { CSVExporter, ICSVExporter } from "./utils/CSVExporter"
import { IURLProvider, URLProvider } from "./utils/URLProvider"
import { IURLUtils, URLUtils } from "./utils/URLUtils"
import { CandidateUploadValidator, ICandidateUploadValidator } from "./services/CandidateUploadValidator"

const container = new Container()

/**
 * Singletons
 */
container.bind<IFirebase>(TYPES.IFirebase).to(Firebase).inSingletonScope()

/**
 * Services
 */
container.bind<IScoreService>(TYPES.IScoreService).to(ScoreService)
container.bind<IAuthService>(TYPES.IAuthService).to(AuthService)

/**
 * Classes
 */
container.bind<IURLUtils>(TYPES.IURLUtils).to(URLUtils)
container.bind<IURLProvider>(TYPES.IURLProvider).to(URLProvider)
container.bind<IRouter>(TYPES.IRouter).to(Router)
container.bind<IValidator>(TYPES.IValidator).to(Validator)
container.bind<ICSVExporter>(TYPES.ICSVExporter).to(CSVExporter)
container.bind<ICandidateUploadValidator>(TYPES.ICandidateUploadValidator).to(CandidateUploadValidator)

/**
 * Factories
 */
container.bind<IDataTableFactory>(TYPES.IDataTableFactory).to(DataTableFactory)

export { container }

import { injectable } from "inversify"
import { Score } from "../models/Score"

export interface IValidator {
    /**
     * Validates scores by making sure all required
     * fields are filled out.
     * @param scores The scores
     * @returns The invalid candidate ids
     */
    isValidScores(scores: Score[]): string[]
}

@injectable()
export class Validator implements IValidator {
    isValidScores(scores: Score[]): string[] {
        let invalidIds: string[] = []

        scores.forEach((score) => {
            if (
                score.adaptability === 0 ||
                score.creativeThinking === 0 ||
                score.grit === 0 ||
                score.technicalSkills === 0 ||
                score.notes === "" ||
                score.recommendation === ""
            ) {
                invalidIds.push(score.cPid)
            }
        })

        return invalidIds
    }
}

import { Component } from "react"
import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material"
import { Score } from "../models/Score"
import { container } from "../DIContainer"
import { IScoreService } from "../services/ScoreService"
import { Dictionary } from "../TypeAliases"
import { TYPES } from "../Types"
import { backButtonStyle, boxStyle, formStyle, stackStyle, updateButtonStyle } from "./styles/ReassignCandidates.style"
import { AlertBar } from "../ui-kit/AlertBar"
import { AlertBarType } from "../models/AlertBarType"
import { IRouter } from "../Router"

interface ReassignCandidatesProps {}

export interface ReassignCandidatesState {
    isLoading: boolean
    scores: Score[]
    candidates: Dictionary // e.g. { "cPid123": "Harry Potter"}
    reviewers: Dictionary // e.g. { "Harry Potter": { rPid: "rPid123", rFirstName: "Harry", rLastName: "Potter"}}
    selectedCandidateId: string
    selectedCandidatesReviewerName: string
    selectedReviewerName: string
    goToAdminDashboard: boolean
    autoDismiss: boolean
    messageType: AlertBarType | undefined
    message: string | null
}

export class ReassignCandidates extends Component<ReassignCandidatesProps, ReassignCandidatesState> {
    private scoreService: IScoreService = container.get<IScoreService>(TYPES.IScoreService)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)

    state: ReassignCandidatesState = {
        isLoading: true,
        scores: [],
        candidates: {},
        reviewers: {},
        selectedCandidateId: "",
        selectedCandidatesReviewerName: "",
        selectedReviewerName: "",
        goToAdminDashboard: false,
        autoDismiss: true,
        messageType: undefined,
        message: null
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Box sx={boxStyle}>
                    <CircularProgress />
                </Box>
            )
        }

        if (this.state.goToAdminDashboard) {
            this.router.goToAdminDashboard()
        }

        return (
            <Container maxWidth="xl">
                <Button id="back-button" sx={backButtonStyle} variant="outlined" onClick={this.backToAdminHomeClicked}>
                    Back to Admin Home
                </Button>
                <Stack sx={stackStyle}>
                    <Typography variant="h4" id="reassign-title">
                        Reassign Candidates
                    </Typography>
                    {this.createCandidateIdDropDown()}
                    {this.createReviewerDropDown()}
                    <Button sx={updateButtonStyle} id="update-button" variant="contained" onClick={this.updateClicked}>
                        Update
                    </Button>
                </Stack>

                {this.state.message && (
                    <AlertBar
                        autoDismiss={this.state.autoDismiss}
                        type={this.state.messageType}
                        message={this.state.message}
                        onClose={this.onPopupClose}
                    />
                )}
            </Container>
        )
    }

    onPopupClose = () => {
        this.setState({
            autoDismiss: true,
            message: null,
            messageType: undefined
        })
    }

    backToAdminHomeClicked = () => {
        this.setState({ goToAdminDashboard: true })
    }

    onCandidateChange = (event: SelectChangeEvent<string>) => {
        const candidateId: string = event.target.value
        this.setState({ selectedCandidateId: candidateId })
        this.getCandidatesReviewerName(candidateId)
    }

    onReviewerChange = (event: SelectChangeEvent<string>) => {
        const reviewerName: string = event.target.value
        this.setState({ selectedReviewerName: reviewerName })
    }

    updateClicked = () => {
        if (this.validateUpdate()) {
            this.saveCandidateReviewerChange()
        }
    }

    validateUpdate = (): boolean => {
        let message: string = ""
        if (!this.state.selectedCandidateId) {
            message = "CandidateId is missing. Please make a selection before updating."
        } else if (!this.state.selectedReviewerName) {
            message = "Reviewer is missing. Please make a selection before updating."
        } else if (this.state.selectedCandidatesReviewerName === this.state.selectedReviewerName) {
            message = "This candidate is already paired with this reviewer."
        }

        if (message) {
            this.setState({ message: message, messageType: AlertBarType.info })
            return false
        }

        return true
    }

    // Private Functions

    private loadData() {
        this.scoreService
            .getAllScores()
            .then((scores) => {
                this.setState({
                    scores: scores,
                    candidates: this.filterScoresForCandidates(scores),
                    reviewers: this.filterScoresForReviewers(scores)
                })
            })
            .catch((_) => {
                this.setState({
                    autoDismiss: false,
                    messageType: AlertBarType.error,
                    message: "Failed to load the page. Please refresh and try again."
                })
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    private saveCandidateReviewerChange() {
        const scores: Score[] = this.generateScoresToSave()
        this.scoreService
            .saveScores(scores)
            .then(() => {
                this.setState({
                    messageType: AlertBarType.success,
                    message: "This candidate's reviewer has been updated."
                })
                this.resetForm()
            })
            .catch((_) => {
                this.setState({
                    autoDismiss: false,
                    messageType: AlertBarType.error,
                    message: "Failed to update the candidate with this reviewer. Please refresh and try again."
                })
            })
    }

    private generateScoresToSave(): Score[] {
        let scores: Score[] = []
        let candidateToUpdate = this.state.scores.find((score) => score.cPid === this.state.selectedCandidateId)

        if (candidateToUpdate) {
            const matchedReviewer = this.state.reviewers[this.state.selectedReviewerName]

            if (matchedReviewer) {
                candidateToUpdate.rPid = matchedReviewer.rPid
                candidateToUpdate.rFirstName = matchedReviewer.rFirstName
                candidateToUpdate.rLastName = matchedReviewer.rLastName
                scores.push(candidateToUpdate)
            }
        }

        return scores
    }

    private resetForm() {
        this.loadData()
        this.createCandidateIdDropDown()
        this.createReviewerDropDown()
        this.setState({
            selectedCandidateId: "",
            selectedCandidatesReviewerName: "",
            selectedReviewerName: ""
        })
    }

    private filterScoresForCandidates(scores: Score[]): Dictionary {
        let candidates: Dictionary = {}
        scores.forEach((score) => {
            candidates[score.cPid] = `${score.rFirstName} ${score.rLastName}`
        })
        return candidates
    }

    private filterScoresForReviewers(scores: Score[]): Dictionary {
        let reviewers: Set<string> = new Set()
        scores.forEach((score) => {
            reviewers.add(`${score.rFirstName} ${score.rLastName}`)
        })

        let reviewersDictionary: Dictionary = {}
        reviewers.forEach((reviewer) => {
            let score: Score | undefined = scores.find((score) => score.rFirstName + " " + score.rLastName === reviewer)
            if (score) {
                reviewersDictionary[reviewer] = {
                    rPid: score.rPid,
                    rFirstName: score.rFirstName,
                    rLastName: score.rLastName
                }
            }
        })

        return reviewersDictionary
    }

    private getCandidatesReviewerName = (candidateId: string) => {
        const reviewer: string = this.state.candidates[candidateId]
        this.setState({ selectedCandidatesReviewerName: reviewer })
    }

    private createCandidateIdDropDown = () => {
        const items = this.generateDropdownItems(this.state.candidates)

        return (
            <FormControl variant="standard" sx={formStyle}>
                <InputLabel id="candidateToReassignLabel">Select a candidate to Reassign</InputLabel>
                <Select
                    labelId="candidateToReassignLabel"
                    id="candidateToReassign"
                    label="candidate"
                    onChange={this.onCandidateChange}
                    value={this.state.selectedCandidateId}
                >
                    {items}
                </Select>
            </FormControl>
        )
    }

    private createReviewerDropDown = () => {
        const items = this.generateDropdownItems(this.state.reviewers)

        return (
            <FormControl variant="standard" sx={formStyle}>
                <InputLabel id="newReviewerLabel">Select a new Reviewer</InputLabel>
                <Select
                    labelId="newReviewerLabel"
                    id="newReviewer"
                    label="reviewer"
                    onChange={this.onReviewerChange}
                    value={this.state.selectedReviewerName}
                >
                    {items}
                </Select>
            </FormControl>
        )
    }

    private generateDropdownItems(collection: Dictionary): JSX.Element[] {
        const keys = Object.keys(collection)
        keys.sort()
        return keys.map((key) => {
            return (
                <MenuItem key={key} value={key}>
                    {key}
                </MenuItem>
            )
        })
    }
}

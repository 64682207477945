import { Alert, Box, Button, Container, Stack, TextField, Typography } from "@mui/material"
import { Component } from "react"
import { container } from "../DIContainer"
import { IRouter } from "../Router"
import { IAuthService } from "../services/AuthService"
import { TYPES } from "../Types"

interface AdminLoginProps {}

export interface AdminLoginState {
    username: string
    password: string
    usernameHasError: boolean
    passwordHasError: boolean
    usernameErrorText: string
    passwordErrorText: string
    errorMessage: string | null
    goToAdminDashboard: boolean
}

export class AdminLogin extends Component<AdminLoginProps, AdminLoginState> {
    private authService: IAuthService = container.get<IAuthService>(TYPES.IAuthService)
    private router: IRouter = container.get<IRouter>(TYPES.IRouter)

    state: AdminLoginState = {
        username: "",
        password: "",
        usernameHasError: false,
        passwordHasError: false,
        usernameErrorText: "",
        passwordErrorText: "",
        errorMessage: null,
        goToAdminDashboard: false
    }

    render() {
        if (this.state.goToAdminDashboard) {
            this.router.goToAdminDashboard()
        }

        return (
            <Container maxWidth="sm">
                <Box
                    sx={{
                        border: "1px solid #f5f5f5",
                        borderRadius: 2,
                        marginTop: 20,
                        padding: 5,
                        boxShadow: "0px 0px 2px #dddddd"
                    }}
                >
                    <Stack spacing={5} alignItems="center">
                        <Typography variant="h4">Login</Typography>
                        <TextField
                            id="username-textfield"
                            label="Email Address"
                            onChange={this.onTextFieldChange}
                            error={this.state.usernameHasError}
                            helperText={this.state.usernameErrorText}
                            sx={{ width: "90%" }}
                            required
                        />
                        <TextField
                            id="password-textfield"
                            label="Password"
                            type="password"
                            onChange={this.onTextFieldChange}
                            error={this.state.passwordHasError}
                            helperText={this.state.passwordErrorText}
                            sx={{ width: "90%" }}
                            required
                        />
                        <Button id="primary-button" variant="contained" onClick={this.primaryButtonClicked} sx={{ width: "90%", height: 60 }}>
                            Log In
                        </Button>

                        {this.state.errorMessage && (
                            <Alert id="error-alert" severity="error">
                                {this.state.errorMessage}
                            </Alert>
                        )}
                    </Stack>
                </Box>
            </Container>
        )
    }

    /**
     * Private
     */

    private primaryButtonClicked = () => {
        if (!this.isValidForm()) {
            return
        }

        this.authService
            .login(this.state.username, this.state.password)
            .then(() => {
                this.setState({ goToAdminDashboard: true })
            })
            .catch((_) => {
                this.setState({
                    errorMessage: "Logging in failed. Please double check your username and password"
                })
            })
    }

    private onTextFieldChange = (event: any) => {
        const value = event.target.value

        switch (event.currentTarget.id) {
            case "username-textfield":
                this.setState({ username: value })
                break
            case "password-textfield":
                this.setState({ password: value })
                break
        }
    }

    private isValidForm = (): boolean => {
        this.resetErrors()

        let isValid = true

        if (this.state.username.length === 0) {
            isValid = false
            this.setState({
                usernameHasError: true,
                usernameErrorText: "Please provide a username"
            })
        }

        if (this.state.password.length === 0) {
            isValid = false
            this.setState({
                passwordHasError: true,
                passwordErrorText: "Please provide a password"
            })
        }

        return isValid
    }

    private resetErrors = () => {
        this.setState({
            usernameHasError: false,
            usernameErrorText: "",
            passwordHasError: false,
            passwordErrorText: ""
        })
    }
}

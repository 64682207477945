/* istanbul ignore file */
import { FirebaseApp, initializeApp } from "firebase/app"
import {
    collection,
    Firestore,
    getDocs,
    getFirestore,
    QuerySnapshot,
    FirestoreDataConverter,
    QueryConstraint,
    query,
    doc,
    updateDoc,
    writeBatch
} from "firebase/firestore"
import { Auth, getAuth, signInWithEmailAndPassword, UserCredential } from "firebase/auth"
import { Environment } from "../Environment"
import { injectable } from "inversify"
import { Dictionary } from "../TypeAliases"
import { Convertible } from "../models/Score"

const FirebaseConfig = {
    local: {
        apiKey: "AIzaSyBOB-geGqKxnGv_RN24AaRPWIJ4HQGTUsw",
        authDomain: "new-tech-feedback.firebaseapp.com",
        databaseURL: "https://new-tech-feedback.firebaseio.com",
        projectId: "new-tech-feedback",
        storageBucket: "new-tech-feedback.appspot.com",
        messagingSenderId: "584844028592",
        appId: "1:584844028592:web:57dbf5c07e4a329e972975",
        measurementId: "G-Q67Z4W1BDS"
    },
    prod: {
        apiKey: "AIzaSyBOB-geGqKxnGv_RN24AaRPWIJ4HQGTUsw",
        authDomain: "new-tech-feedback.firebaseapp.com",
        databaseURL: "https://new-tech-feedback.firebaseio.com",
        projectId: "new-tech-feedback",
        storageBucket: "new-tech-feedback.appspot.com",
        messagingSenderId: "584844028592",
        appId: "1:584844028592:web:57dbf5c07e4a329e972975",
        measurementId: "G-Q67Z4W1BDS"
    }
}

export enum FirebaseCollection {
    scores2021 = "scores_2021",
    scores2022 = "scores_2022",
    scores2023 = "scores_2023",
    scores2024 = "scores_2024"
}

export interface IFirebase {
    /**
     *
     * @param collectionName The collection name
     * @param converter An optional custom object converter
     */
    getAllFrom<T>(collectionName: FirebaseCollection, converter: FirestoreDataConverter<T>): Promise<QuerySnapshot<T>>

    /**
     *
     * @param collectionName The collection name
     * @param whereContraint The query constraint
     * @param converter An optional custom object converter
     */
    getFrom<T>(collectionName: FirebaseCollection, whereContraint: QueryConstraint, converter: FirestoreDataConverter<T>): Promise<QuerySnapshot<T>>

    /**
     *
     * @param collectionName The collection name
     * @param documentId The document id
     * @param data The data to update
     */
    updateDocument(collectionName: FirebaseCollection, documentId: string, data: Dictionary): Promise<void>

    /**
     *
     * @param collectionName The collection name
     * @param sourceData The array of data to batch update
     */
    batchUpdate(collectionName: FirebaseCollection, sourceData: Convertible[]): Promise<void>

    /**
     * Adds a new array of data to the collection provided.
     * The array of data should not contain document IDs as these will be set
     * for each item being imported to the collection.
     *
     * @param collectionName The collection name
     * @param sourceData The array of data to batch update
     */
    batchAdd(collectionName: FirebaseCollection, sourceData: Convertible[]): Promise<void>

    /**
     *
     * @param username The username e.g. email address
     * @param password The password
     */
    login(username: string, password: string): Promise<UserCredential>
}

@injectable()
export class Firebase implements IFirebase {
    app: FirebaseApp
    db: Firestore
    auth: Auth

    constructor() {
        switch (process.env.NODE_ENV) {
            case Environment.prod:
                this.app = initializeApp(FirebaseConfig.prod)
                break
            default:
                this.app = initializeApp(FirebaseConfig.local)
        }

        this.db = getFirestore(this.app)
        this.auth = getAuth(this.app)
    }

    /**
     * Public Functions
     */

    getAllFrom<T>(collectionName: FirebaseCollection, converter: FirestoreDataConverter<T>): Promise<QuerySnapshot<T>> {
        const collectionRef = collection(this.db, collectionName).withConverter(converter)
        const q = query(collectionRef)
        return getDocs(q)
    }

    getFrom<T>(collectionName: FirebaseCollection, whereContraint: QueryConstraint, converter: FirestoreDataConverter<T>): Promise<QuerySnapshot<T>> {
        const collectionRef = collection(this.db, collectionName).withConverter(converter)
        const q = query(collectionRef, whereContraint)
        return getDocs(q)
    }

    updateDocument(collectionName: FirebaseCollection, documentId: string, data: { [key: string]: any }): Promise<void> {
        const docRef = doc(this.db, collectionName, documentId)
        return updateDoc(docRef, { data: data })
    }

    batchUpdate(collectionName: FirebaseCollection, sourceData: Convertible[]): Promise<void> {
        const batchOp = writeBatch(this.db)

        sourceData.forEach((item) => {
            const json = item.toJson()
            batchOp.update(doc(this.db, collectionName, item.documentId), json)
        })

        return batchOp.commit()
    }

    batchAdd(collectionName: FirebaseCollection, sourceData: Convertible[]): Promise<void> {
        const batchLimit: number = 100
        const numberOfBatches = Math.ceil(sourceData.length / batchLimit)
        let batches = []

        for (let i = 0; i < numberOfBatches; i++) {
            batches.push(writeBatch(this.db))
        }

        for (let i = 0; i < sourceData.length; i++) {
            const item = sourceData[i]
            const itemRef = doc(collection(this.db, collectionName))
            item.documentId = itemRef.id

            const json = item.toJson()
            batches[Math.floor(i / batchLimit)].set(itemRef, json)
        }

        const committedBatches = batches.map((batch) => {
            return batch.commit()
        })

        return Promise.all(committedBatches)
            .then(() => {
                return Promise.resolve()
            })
            .catch((error: any) => {
                return Promise.reject(error)
            })
    }

    login(username: string, password: string): Promise<UserCredential> {
        return signInWithEmailAndPassword(this.auth, username, password)
    }
}

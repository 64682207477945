import { Stack, TextField, Typography } from "@mui/material"
import { Component } from "react"
import { Score } from "../models/Score"

interface EvaluationNotesProps {
    score: Score
    onChange: (newValue: string) => void
}

interface EvaluationNotesState {}

export class EvaluationNotes extends Component<EvaluationNotesProps, EvaluationNotesState> {
    render() {
        return (
            <Stack spacing={2}>
                <Typography align="left" variant="h6">
                    Evaluation Notes
                </Typography>
                <TextField id="evaluation-notes" rows={10} value={this.props.score.notes} onChange={this.onNoteChange} multiline fullWidth />
            </Stack>
        )
    }

    onNoteChange = (event: any) => {
        this.props.onChange(event.target.value)
    }
}

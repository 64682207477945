import { Dictionary } from "../TypeAliases"
import { injectable } from "inversify"

export enum UploadError {
    empty = 0,
    duplicateCandidateIds,
    invalidHeaderRow,
    missingRequiredFields
}

export interface CandidateUploadError {
    error: UploadError
    message: string
}

export interface CandidateUploadResult {
    errors: CandidateUploadError[] | null
    data: Dictionary[]
}

export interface ICandidateUploadValidator {
    validate(data: Dictionary[]): CandidateUploadResult
}

@injectable()
export class CandidateUploadValidator implements ICandidateUploadValidator {
    validate(data: Dictionary[]): CandidateUploadResult {
        if (!data.length) {
            const errors = [
                {
                    error: UploadError.empty,
                    message: "The CSV file is empty. Please check the file."
                }
            ]
            return { errors: errors, data: [] }
        }

        let errors: CandidateUploadError[] = []

        const validateHeaderRow = this.validateHeaderRow(data)
        if (validateHeaderRow) {
            errors.push(validateHeaderRow)
        }

        const dataIntegrityCheck = this.dataIntegrityCheck(data)
        if (dataIntegrityCheck) {
            errors.push(dataIntegrityCheck)
        }

        const duplicateCandidateId = this.duplicateCandidateIdCheck(data)
        if (duplicateCandidateId) {
            errors.push(duplicateCandidateId)
        }

        return { errors: !errors.length ? null : errors, data: data }
    }

    private validateHeaderRow(data: Dictionary[]): CandidateUploadError | null {
        if (data.length <= 0) {
            return {
                error: UploadError.empty,
                message: "The CSV file is empty. Please check the file."
            }
        }

        let message = ""

        /**
         * If any one of the headers is incorrect, show error message.
         * This is not checking for camelCase here because
         * before we parse, we transform the header row to be
         * all lowercase. Nor is this checking for header row item order.
         * This is checking for proper naming and spelling, though.
         */
        if (
            data[0]["cPid"] === undefined ||
            data[0]["rPid"] === undefined ||
            data[0]["rFirstName"] === undefined ||
            data[0]["rLastName"] === undefined
        ) {
            message += "Invalid header row. Please make sure the header matches the following format: cPid, rPid, rFirstName, rLastName."
        }

        /**
         * All headers are valid
         */
        if (message === "") {
            return null
        }

        return {
            error: UploadError.invalidHeaderRow,
            message: message
        }
    }

    private dataIntegrityCheck(data: Dictionary[]): CandidateUploadError | null {
        let invalidRows: number[] = []

        data.forEach((entry, index) => {
            const cPid = entry.cPid
            const rPid = entry.rPid
            const rFirstName = entry.rFirstName
            const rLastName = entry.rLastName

            const rowIndex = index + 2

            if (!cPid || !rPid || !rFirstName || !rLastName) {
                invalidRows.push(rowIndex)
            }
        })

        /**
         * All rows have valid data
         */
        if (!invalidRows.length) {
            return null
        }

        /**
         * Invalid rows found.
         * Create a friendly error message.
         */
        let message = "The following rows are missing required fields:"

        invalidRows.forEach((rowNumber, index) => {
            if (index === 0) {
                message += ` Row ${rowNumber}`
            } else {
                message += `, Row ${rowNumber}`
            }
        })

        return {
            error: UploadError.missingRequiredFields,
            message: message
        }
    }

    private duplicateCandidateIdCheck(data: Dictionary[]): CandidateUploadError | null {
        let uniqueCandidateIds: Set<string> = new Set()
        let duplicateCandidateIds: Set<string> = new Set()

        /**
         * Find duplicate ids
         */
        data.forEach((entry) => {
            let cPid = entry.cPid
            if (!cPid) {
                return
            }

            if (uniqueCandidateIds.has(cPid)) {
                duplicateCandidateIds.add(cPid)
            } else {
                uniqueCandidateIds.add(cPid)
            }
        })

        /**
         * No duplicates found
         */
        if (!duplicateCandidateIds.size) {
            return null
        }

        /**
         * Duplicate candidate ids found.
         * Create a friendly error message.
         */
        let message = "Duplicate candidate ids found:"
        const iterator = duplicateCandidateIds.values()

        for (let i = 0; i < duplicateCandidateIds.size; i++) {
            const id = iterator.next().value
            if (i === 0) {
                message += ` ${id}`
            } else {
                message += `, ${id}`
            }
        }

        return {
            error: UploadError.duplicateCandidateIds,
            message: message
        }
    }
}

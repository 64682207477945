import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography, Stack } from "@mui/material"
import { Component } from "react"
import { Score } from "../models/Score"
import { Dictionary } from "../TypeAliases"

interface EvaluationCategoryProps {
    score: Score
    onChange: (option: EvaluationCategoryData) => void
}

interface EvaluationCategoryState {
    selectedData: Dictionary
}

export interface EvaluationCategoryData {
    category: EvalCategory
    value: number
}

export enum EvalCategory {
    creativeThinking = "creative-thinking",
    adaptability = "adaptability",
    technicalSkills = "technical-skills",
    grit = "grit"
}

export enum EvalCategoryValueKeys {
    notDemonstrated = "1",
    demonstrated = "2",
    veryDemonstrated = "3"
}

export class EvaluationCategory extends Component<EvaluationCategoryProps, EvaluationCategoryState> {
    render() {
        return (
            <Box
                sx={{
                    border: "1px solid #d3d3d3",
                    borderRadius: 1,
                    overflow: "hidden"
                }}
            >
                <Grid container>
                    <this.HeaderRow />
                    <this.CreativeThinking />
                    <this.Adaptability />
                    <this.TechnicalSkills />
                    <this.Grit />
                    <this.TotalScore />
                </Grid>
            </Box>
        )
    }

    /**
     * Public Functions
     */

    creativeThinkingOnChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        this._onChangeFor(EvalCategory.creativeThinking, value)
    }

    adaptabilityOnChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        this._onChangeFor(EvalCategory.adaptability, value)
    }

    technicalSkillsOnChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        this._onChangeFor(EvalCategory.technicalSkills, value)
    }

    gritOnChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        this._onChangeFor(EvalCategory.grit, value)
    }

    /**
     * Private Functions
     */

    private HeaderRow = () => {
        return (
            <Grid container id="header-grid" height={100} sx={{ backgroundColor: "#d3d3d3" }} alignItems={"center"}>
                <Grid item xs={4} textAlign="left" paddingLeft={2}>
                    Evaluation Category
                </Grid>
                <Grid item xs={2}>
                    3<br />
                    <br />
                    Very Clearly Demonstrated
                </Grid>
                <Grid item xs={2}>
                    2<br />
                    <br />
                    Demonstrated
                </Grid>
                <Grid item xs={2}>
                    1<br />
                    <br />
                    Not Clearly
                    <br />
                    Demonstrated
                </Grid>
                <Grid item xs={2}>
                    Category Score
                </Grid>
            </Grid>
        )
    }

    private CreativeThinking = () => {
        return (
            <Grid container id="creative-thinking-grid" height={150} alignItems={"center"} borderBottom="1px solid #d3d3d3">
                <Grid item xs={4} textAlign="left" padding={2}>
                    <Typography variant="h6">Creative Thinking</Typography>
                    <Typography>&#8226; Thinks outside the box</Typography>
                    <Typography>&#8226; Innovative suggestions for product improvements</Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        aria-labelledby="ct-row-radio-buttons-group-label"
                        name="ct-row-radio-buttons-group"
                        onChange={this.creativeThinkingOnChange}
                        value={this.getValueFor(EvalCategory.creativeThinking)}
                    >
                        <Stack direction="row" spacing={18} sx={{ marginLeft: "14%" }}>
                            <FormControlLabel value={EvalCategoryValueKeys.veryDemonstrated} control={<Radio />} label="" />
                            <FormControlLabel value={EvalCategoryValueKeys.demonstrated} control={<Radio />} label="" />
                            <FormControlLabel value={EvalCategoryValueKeys.notDemonstrated} control={<Radio />} label="" />
                        </Stack>
                    </RadioGroup>
                </Grid>
                <Grid item xs={2}>
                    {this.getValueFor(EvalCategory.creativeThinking)}
                </Grid>
            </Grid>
        )
    }

    private Adaptability = () => {
        return (
            <Grid container id="adaptability-grid" height={150} alignItems={"center"} borderBottom="1px solid #d3d3d3">
                <Grid item xs={4} textAlign="left" padding={2}>
                    <Typography variant="h6">Adaptability</Typography>
                    <Typography>&#8226; Demonstrates curiosity; eager to try new things</Typography>
                    <Typography>&#8226; Actively works towards a growth mindset</Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        aria-labelledby="adapt-row-radio-buttons-group-label"
                        name="adapt-row-radio-buttons-group"
                        onChange={this.adaptabilityOnChange}
                        value={this.getValueFor(EvalCategory.adaptability)}
                    >
                        <Stack direction="row" spacing={18} sx={{ marginLeft: "14%" }}>
                            <FormControlLabel value={EvalCategoryValueKeys.veryDemonstrated} control={<Radio />} label="" />
                            <FormControlLabel value={EvalCategoryValueKeys.demonstrated} control={<Radio />} label="" />
                            <FormControlLabel value={EvalCategoryValueKeys.notDemonstrated} control={<Radio />} label="" />
                        </Stack>
                    </RadioGroup>
                </Grid>
                <Grid item xs={2}>
                    {this.getValueFor(EvalCategory.adaptability)}
                </Grid>
            </Grid>
        )
    }

    private TechnicalSkills = () => {
        return (
            <Grid container id="technical-skills-grid" height={150} alignItems={"center"} borderBottom="1px solid #d3d3d3">
                <Grid item xs={4} textAlign="left" padding={2}>
                    <Typography variant="h6">Technical Skills</Typography>
                    <Typography>&#8226; Ability to communicate solution/process</Typography>
                    <Typography>&#8226; Solves coding prompt thoroughly and clearly</Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        aria-labelledby="tech-skills-row-radio-buttons-group-label"
                        name="tech-skills-row-radio-buttons-group"
                        onChange={this.technicalSkillsOnChange}
                        value={this.getValueFor(EvalCategory.technicalSkills)}
                    >
                        <Stack direction="row" spacing={18} sx={{ marginLeft: "14%" }}>
                            <FormControlLabel value={EvalCategoryValueKeys.veryDemonstrated} control={<Radio />} label="" />
                            <FormControlLabel value={EvalCategoryValueKeys.demonstrated} control={<Radio />} label="" />
                            <FormControlLabel value={EvalCategoryValueKeys.notDemonstrated} control={<Radio />} label="" />
                        </Stack>
                    </RadioGroup>
                </Grid>
                <Grid item xs={2}>
                    {this.getValueFor(EvalCategory.technicalSkills)}
                </Grid>
            </Grid>
        )
    }

    private Grit = () => {
        return (
            <Grid container id="grit-grid" height={200} alignItems={"center"}>
                <Grid item xs={4} textAlign="left" padding={2}>
                    <Typography variant="h6">Grit</Typography>
                    <Typography>&#8226; Demonstrates PERSEVERENCE and/or RESILIENCE through leadership, personal growth, etc.</Typography>
                    <Typography>&#8226; Tenaciously DRIVES positive outcomes even in the face of adversities</Typography>
                </Grid>
                <Grid item xs={6}>
                    <RadioGroup
                        row
                        aria-labelledby="grit-row-radio-buttons-group-label"
                        name="grit-row-radio-buttons-group"
                        onChange={this.gritOnChange}
                        value={this.getValueFor(EvalCategory.grit)}
                    >
                        <Stack direction="row" spacing={18} sx={{ marginLeft: "14%" }}>
                            <FormControlLabel value={EvalCategoryValueKeys.veryDemonstrated} control={<Radio />} label="" />
                            <FormControlLabel value={EvalCategoryValueKeys.demonstrated} control={<Radio />} label="" />
                            <FormControlLabel value={EvalCategoryValueKeys.notDemonstrated} control={<Radio />} label="" />
                        </Stack>
                    </RadioGroup>
                </Grid>
                <Grid item xs={2}>
                    {this.getValueFor(EvalCategory.grit)}
                </Grid>
            </Grid>
        )
    }

    private TotalScore = () => {
        return (
            <Grid container id="total-score-grid" height={50} sx={{ backgroundColor: "#ffbb00" }} alignItems={"center"}>
                <Grid item xs={10} textAlign="right" paddingLeft={2}>
                    <Typography variant="h6">Total Evaluation Score</Typography>
                </Grid>
                <Grid id="total-score" item xs={2}>
                    <Typography variant="h6">{this.props.score.totalScore()}</Typography>
                </Grid>
            </Grid>
        )
    }

    private _onChangeFor = (category: EvalCategory, value: string) => {
        const total: EvaluationCategoryData = {
            category: category,
            value: Number(value)
        }

        this.props.onChange(total)
    }

    private getValueFor = (category: EvalCategory) => {
        switch (category) {
            case EvalCategory.creativeThinking:
                return String(this.props.score.creativeThinking)
            case EvalCategory.adaptability:
                return String(this.props.score.adaptability)
            case EvalCategory.technicalSkills:
                return String(this.props.score.technicalSkills)
            case EvalCategory.grit:
                return String(this.props.score.grit)
        }
    }
}

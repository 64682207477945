export const drawerStyle = {
    "& .MuiDrawer-paper": {
        position: "relative"
    }
}

export const headerStackStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 2
}

export const iconStyle = {
    width: 24,
    height: 24
}
